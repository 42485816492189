<script setup lang="ts">
import { navigateToNotFound } from '~/common/helpers/navigate'

const props = defineProps({
  error: Object,
})

onMounted(() => {
  if (props.error && props.error.statusCode === 404) {
    navigateToNotFound()
  }
})
</script>
